<template>
  <div id="agency">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />个人中心
        </van-col>
        <van-col span="14" style="text-align: right" @click="onEditClick">
          <svg-icon :data_iconName="'edit'" :className="'edit_icon'" />
        </van-col>
      </van-row>
    </div>
    <div class="agency-wrapper">
      <div class="agency-container">
        <div class="agency-avatar">
          <!-- <img :src="user.avatar" alt="" /> -->
          <van-image
            round
            style="width: 1.5rem; height: 1.5rem"
            :src="user.avatar"
          >
            <template v-slot:error
              ><span style="font-size: 10px">暂无头像</span></template
            >
          </van-image>
        </div>

        <van-row>
          <van-col span="24"
            ><div class="star-text">
              <!-- <van-icon
                color="#FFAA00"
                v-for="(item, index) in 5"
                :key="index"
                name="star"
              /> -->
              <van-rate
                v-model="company.star"
                :size="25"
                color="#FFAA00"
                void-icon="star"
                void-color="#eee"
                readonly
              /></div
          ></van-col>
          <van-col span="24"
            ><div class="main-text">{{ company.name }}</div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              {{
                company.name_en
                  ? company.name_en
                  : "English name to be improved"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              地址：{{ company.address ? company.address : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              法人代表：{{
                company.legal_person ? company.legal_person : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              法人电话：{{ company.telephone ? company.telephone : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              联系人：{{
                company.contact_person ? company.contact_person : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              联系电话：{{
                company.contact_mobile ? company.contact_mobile : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              社会信用代码:{{
                company.social_credit_card
                  ? company.social_credit_card
                  : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              发证单位：{{ company.unit ? company.unit : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              许可证号：<span>{{
                company.license_no ? company.license_no : "未完善"
              }}</span>
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              许可文号：{{
                company.license_file_no ? company.license_file_no : "未完善"
              }}
            </div></van-col
          >

          <van-col span="24"
            ><div class="agency-text">
              经营场所：{{
                company.place_of_business ? company.place_of_business : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              传真：{{ company.fax ? company.fax : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              质保金：{{
                company.deposit > 0 ? company.deposit + "万元" : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              质保银行：{{
                company.deposit_bank ? company.deposit_bank : "未完善"
              }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              <!-- <div style="height:80px;line-height:80px;display:inline-block"></div> -->
              质保凭条：<van-uploader
                v-if="fileList.length > 0"
                :deletable="false"
                readonly
                v-model="fileList"
                :max-count="1"
              />
              <span v-else>未完善</span>
            </div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">
              营业执照：<van-uploader
                v-if="fileList1.length > 0"
                :deletable="false"
                readonly
                v-model="fileList1"
                :max-count="1"
              />
              <span v-else>未完善</span>
            </div></van-col
          >
          <!-- <van-col span="24"
            ><div class="agency-text">所属机构：安吉导游协会</div></van-col
          >
          <van-col span="24"
            ><div class="agency-text">所属旅行社： 安吉两山旅行</div></van-col
          > -->
        </van-row>
        <div class="qr-container">
          <van-row>
            <van-col span="24"
              ><div class="agency-text">
                <div class="qr-img" ref="qrCodeUrl" v-if="downloadData.url">
                  <!-- <img width="120" v-if="company.qrcode" :src="company.qrcode" alt="" />
                  <div v-else>未完善</div> -->
                  <vue-qr
                    :text="downloadData.url"
                    :margin="0"
                    colorDark="#333"
                    colorLight="#fff"
                    :logoScale="0.3"
                    :size="200"
                  ></vue-qr>
                </div>
              </div>
            </van-col>
            <van-col span="24"
              ><a
                :href="'tel:' + company.contact_mobile"
                class="agency-text"
                style="
                  color: #426cef;
                  text-decoration: underline;
                  display: block;
                "
              >
                {{ company.contact_mobile ? company.contact_mobile : "未完善" }}
              </a></van-col
            >
            <!-- <van-col span="24"
              ><div class="sub-text">{{company.validity_start_date?company.validity_start_date:'未完善'}} - {{company.validity_end_date?company.validity_end_date:"未完善"}}</div></van-col
            > -->
            <van-col span="24"
              ><div class="sub-text state">
                {{ company.status_text }}
              </div></van-col
            >
          </van-row>
        </div>
      </div>
    </div>
    <Tarbar :active="0"></Tarbar>
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
import { Toast } from "vant";
import { QrURL } from "@/services/config";
import vueQr from "vue-qr";
export default {
  name: "profile",
  data() {
    const _self = this;
    return {
      company: {},
      user: {},
      downloadData: {
        url: "",
        icon: "",
      },
      fileList: [],
      fileList1: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    async get_current_company() {
      const res = await this.$apis.get_current_company();
      this.company = res.data.company;
      if (res.data.company.deposit_receipt) {
        this.fileList = [{ url: res.data.company.deposit_receipt }];
      }
      if (res.data.company.license_img) {
        this.fileList1 = [{ url: res.data.company.license_img }];
      }
      this.user = res.data.user;
      // this.downloadData.url = `https://visitor.wenlvanquan.com:4002/guider/companyInfo/${res.data.company.id}`//测试
      // this.downloadData.url = `https://visitor.wenlvanquan.com/guider/companyInfo/${res.data.company.id}`//正式
      this.downloadData.url = `${QrURL}/guider/companyInfo/${res.data.company.id}`; //正式
    },
    onEditClick() {
      this.$router.push("/profile/edit");
    },
  },
  components: {
    Tarbar,
    vueQr,
  },
  created() {
    this.get_current_company();
  },
};
</script>

<style scoped>
#agency {
  height: 100vh;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.agency-wrapper {
  padding: 0.4rem;
  position: relative;
}
.agency-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;

  height: 85vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.agency-avatar {
  position: absolute;
  top: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  z-index: 100;
}
.agency-avatar img {
  width: 100%;
  height: 100%;
}

.agency-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 240px;
  height: 240px;
  line-height: 200px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 10px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 17px;
  border-radius: 17px;
  height: 34px;
  width: 80px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  color: #40a9ff;
}
.edit_icon {
  font-size: 0.6rem;
}
.van-image__img,
.van-uploader {
  vertical-align: middle !important;
  z-index: 1;
}
</style>
